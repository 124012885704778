export function ItemCarousel({img,description,company}) {
  return (
    <div className="flex flex-col items-center justify-center gap-[20px]">
      <img
        className="h-[100px] w-[100px] object-contain"
        src={ img }
        alt=""
      />
      <div className="text-center">
        <p className="">{ description }</p>
        <h3 className="text-[18px]  font-bold">{ company }</h3>
      </div>
    </div>
  );
}
