import React from "react";
import img from "../utils/img.json";
import { SwiperSlide } from "swiper/react";
import { Carousel } from "../componentes/home/carousel/carousel";
import { ItemCarousel } from "../componentes/home/carousel/ItemCarousel";
import { Preloader } from "../componentes/share/Preloader";

export function Home() {
  return (
    <div className="App dark:bg-black dark:text-white">
      <Preloader />
      <Carousel>
        {img.map((img) => (
          <SwiperSlide>
            <ItemCarousel
              img={img}
            />
          </SwiperSlide>
        ))}
      </Carousel>
    </div>
  );
}
