export function NotFound() {
    return (
        <div className="flex justify-center items-center h-[90vh] dark:bg-black text-white">
                <div className="flex flex-col justify-center items-center h-[350px] w-[350px] bg-blue-700 rounded-full animate-pulse">
                    <h3 className="text-[25px] font-bold">404</h3>
                    <p className="py-[20px] font-thin">La Ruta a la que intentas ingresar no existe</p>
                    <div className="h-[3px] bg-white w-full dark:bg-black"></div>
                </div>
        </div>
    );
}