import { Link } from "react-router-dom";

export function Card({ producto,to }) {
  return (
    <div
      className="border-gray2 border-[2px] dark:border-blueDark dark:shadow-blueDark pb-[15px]
     rounded-xl space-y-[30px] hover:scale-90 duration-300 hover:shadow-xl"
    >
      <Link to={to}>
        <div className="p-[10px]">
          <img
            className="w-full border-gray2 dark:border-blueDark border-[2px] bg-gray2 dark:bg-blueDark h-[150px] object-contain mx-auto rounded-xl"
            src={producto.img[0]}
            alt={producto.nombre}
          />
        </div>
        <div className="px-[12px]">
          <p className="text-[18px] text-first dark:text-white font-medium lowercase">
            {producto.nombre}
          </p>
        </div>
      </Link>
    </div>
  );
}
