import { SlArrowLeftCircle } from "react-icons/sl";
import { Link } from "react-router-dom";

export function PrevPage({ to }) {
  return (
    <Link to={to}>
      <div className="w-fit h-fit flex gap-[20px] items-center text-first">
        <SlArrowLeftCircle size={25} />
        <div className="font-semibold text-[14px]">Volver</div>
      </div>
    </Link>
  );
}
