import { useParams } from "react-router-dom";
import services from "../utils/Servicios.json";
import { SwiperSlide } from "swiper/react";
import { ItemCarousel } from "../componentes/home/carousel/ItemCarousel";
import { CarouselDetails } from "../componentes/share/CarouselDetails";
import { PrevPage } from "../componentes/share/PrevPage";
import { Title } from "../componentes/share/Title";
import { Description } from "../componentes/share/Description";
import { Preloader } from "../componentes/share/Preloader"

export function ServiceDetails() {
  const { slog } = useParams();
  const service = services.find((serv) => serv.id == slog);

  return (
    <div className="px-[10%] pt-[40px] pb-[90px] space-y-[50px] dark:bg-black dark:text-white">
      <Preloader />
      <div className="">
        <PrevPage to={"/servicios"} />
      </div>
      <Title text={service.nombre} />
      <div className="flex flex-col gap-[40px] md:flex-row justify-center items-center">
        <div className="md:w-[50%] w-[100%]">
          <CarouselDetails>
            {service.img.map((img) => (
              <SwiperSlide>
                <ItemCarousel img={img} />
              </SwiperSlide>
            ))}
          </CarouselDetails>
        </div>

        <Description text={service.descripcion} />
      </div>
    </div>
  );
}
