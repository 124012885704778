import React from "react";
import { RiPhoneLine } from "react-icons/ri";
import { ImLocation } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { LinkFooter } from "./LinkFooter";
import { BsInstagram, BsFacebook, BsWhatsapp } from "react-icons/bs";
import { MediaFooter } from "./MediaFooter";

function Footer() {
  return (
    <div className="w-[100%] h-[550px] flex items-end bg-first bg-[url('https://i.postimg.cc/vTjRSSTF/fachada.jpg')] bg-no-repeat bg-cover px-[5%] ">
      <div className="space-y-[15px] w-full">
        <div className="flex gap-2">
          <MediaFooter to={"https://www.instagram.com/hydrotech_ingenieria/"}>
            <BsInstagram size={25} />
          </MediaFooter>
          <MediaFooter to={"https://www.facebook.com/hydrotechsas"}>
            <BsFacebook size={25} />
          </MediaFooter>
          <MediaFooter
            to={
              "https://api.whatsapp.com/send?phone=573123876489&text=Quiero%20saber%20mas%20sobre%20sus%20servicios"
            }
          >
            <BsWhatsapp size={25} />
          </MediaFooter>
        </div>
        <div>
          <h3 className="text-first text-[20px] font-bold pb-[5px]">
            Contacto :
          </h3>
          <div className="text-first space-y-[5px] ml-8 mt-[10px]">
            <LinkFooter to="https://goo.gl/maps/upMZbeXbjmobAiW68">
              <ImLocation size={23} />
              <p className="">Lugar Km 54 AUT-BOGOTA-GIRARDOT</p>
            </LinkFooter>

            <LinkFooter to="https://api.whatsapp.com/send?phone=573123876489&text=Quiero%20saber%20mas%20sobre%20sus%20servicios">
              <RiPhoneLine size={23} />
              <p className="">3123876489</p>
            </LinkFooter>

            <LinkFooter to="mailto:ventas@hydrotechcolombia.com?subject=Preguntas">
              <HiOutlineMail size={24} />
              <p className="">ventas@hydrotechcolombia.com</p>
            </LinkFooter>
          </div>
        </div>
        <div>
          <div className="bg-first w-full h-[2px]"></div>
          <p className="text-first py-[25px] text-center font-semibold">
            © HYDROTECH, Derechos Reservados
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
