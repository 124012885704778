export function Team({ data }) {
  return (
    <div className="border-[2px] w-fit rounded-xl dark:bg-black dark:border-blueDark dark:shadow-blueDark border-gray2 hover:scale-110 hover:shadow-xl duration-[300ms]">
      <div>
        <img
          className="bg-white dark:bg-black w-full max-w-[250px] object-cover h-[250px] rounded-xl"
          src={data.img}
          alt=""
        />
      </div>
      <div className="text-center py-[10px]">
        <p className="text-[17px] font-bold">{data.name}</p>
        <p className="text-[16px]">{data.position}</p>
      </div>
    </div>
  );
}
