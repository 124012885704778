export function PartImg(ObjImgs) {
  
  const imgs = ObjImgs.imgs

  return (
    <div className="hidden lg:flex flex-col justify-center w-[33%] my-[140px]">
      <div className="flex pl-[210px] py-2">
        <img
          className="animate-pulse object-contain w-[70px] h-[70px] border-dotted border-2 border-[#015FC9] rounded-lg p-2"
          src={imgs.firts}
          alt=""
        />
      </div>
      <div className="py-2 px-[120px]">
        <img
          className="animate-pulse object-contain w-[70px] h-[70px] border-dotted border-2 border-[#015FC9] rounded-lg p-2"
          src={imgs.second}
          alt=""
        />
      </div>
      <div className="flex pl-[210px] py-2">
        <img
          className="animate-pulse object-contain w-[70px] h-[70px] border-dotted border-2 border-[#015FC9] rounded-lg p-2"
          src={imgs.third}
          alt=""
        />
      </div>
    </div>
  );
}
