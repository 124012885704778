import { useParams } from "react-router-dom";
import equipos from "../utils/productos.json";
import { PrevPage } from "../componentes/share/PrevPage";
import { Title } from "../componentes/share/Title";
import { CarouselDetails } from "../componentes/share/CarouselDetails";
import { SwiperSlide } from "swiper/react";
import { ItemCarousel } from "../componentes/home/carousel/ItemCarousel";
import { Description } from "../componentes/share/Description";
import { Preloader } from "../componentes/share/Preloader"

export function EquipmentDetails() {
  const { slog } = useParams();
  const equipo = equipos.find((equipo) => equipo.id == slog);

  return (
    <div className="px-[10%] pt-[40px] pb-[90px] space-y-[50px] dark:bg-black dark:text-white">
      <Preloader/>
      <div>
        <PrevPage to={"/equipos"} />
      </div>
      <Title text={equipo.nombre} />
      <div className="flex flex-col gap-[40px] md:flex-row justify-center items-center">
        <div className="md:w-[50%] w-[100%]">
          <CarouselDetails>
            {equipo.img.map((img) => (
              <SwiperSlide>
                <ItemCarousel img={img}/>
              </SwiperSlide>
            ))}
          </CarouselDetails>
        </div>

        <Description text={equipo.descripcion}/>
      </div>
    </div>
  );
}
