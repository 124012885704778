import React from 'react';
import Cabecera from '../componentes/cabeceraNav';
import { Contacto } from '../componentes/Contacto';
import { Preloader } from '../componentes/share/Preloader';


function Contactanos() {
    return (
        <div className='dark:bg-black dark:text-white'>
            <Preloader/>
            <Cabecera
                paginaActual='Contactanos'
                urlHome=''
                urlPage=''
                namePage='contactanos'
            />

            {/* Section con el formulario de contacto*/}
            <section >
                <Contacto />
            </section>
        </div>
    );
}

export default Contactanos;