import React from "react";

const Cabecera =(props)=>{
    return(
        <div className="py-10">
            <div className="bg-sky dark:bg-[#020617] py-[50px] pl-[15px]">
                <h3 className="text-[35px] lg:text-[50px] font-bold">{props.paginaActual}</h3>
                <div className="flex gap-2 mt-[10px]">
                    <a href="/" className="hover:text-first hover:font-semibold">Inicio</a>
                    <p>/</p>
                    <p>{props.namePage}</p>
                </div>
            </div>
        </div>
    );
}

export default Cabecera;