import React from "react";
// Import Swiper React components
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import { Swiper } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./controles.css";

function Carousel({ children }) {
  return (
    <div className="carousel p-[30px] relative ">
      <Swiper
        className="relative"
        modules={[Navigation, EffectFade, Autoplay, Pagination]}
        spaceBetween={50}
        //cantidad de imagenes en la pantalla
        slidesPerView={1}
        allowTouchMove={true}
        navigation
        effect={"fade"}
        speed={200}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        {children}
      </Swiper>
    </div>
  );
}

export { Carousel };
