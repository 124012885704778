import { BsFillMoonFill, BsFillSunFill } from "react-icons/bs";

export function ButtonMode({setTheme,isDark}){
    return(
        <button onClick={setTheme}>
        {isDark ? (
          <BsFillSunFill className="text-[#facc15]" size={45} />
        ) : (
          <BsFillMoonFill className="text-[#015FC9]" size={45} />
        )}
      </button>
    )
}