import React from "react";
import Cabecera from "../componentes/cabeceraNav";
import { Team } from "../componentes/about/Team";
import { PosterExperience } from "../componentes/about/PosterExperience";
import { WhoUs } from "../componentes/about/WhoUs";
import { InfoClients } from "../componentes/about/InfoClients";

import { Preloader } from "../componentes/share/Preloader";
import { SectionTeam } from "../componentes/about/SectionTeam";
import { SectionClients } from "../componentes/about/SectionClients";
import { SwiperSlide } from "swiper/react";
import { ItemCarousel } from "../componentes/about/ItemCarousel";
import clientes from "../utils/clientes.json";

export function About() {
  return (
    <div className="App dark:bg-black dark:text-white px-[10%] space-y-[50px]">
      <Preloader />
      <Cabecera
        paginaActual="Nosotros"
        urlHome=""
        urlPage=""
        namePage="nosotros"
      />
      <div className="flex flex-col md:flex-row gap-[50px] justify-center items-center">
        <div className="md:w-[35%]">
          <PosterExperience />
        </div>
        <div className="md:w-[50%]">
          <WhoUs />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-[50px] justify-center items-center">
        <div className="md:w-[50%]">
          <InfoClients />
        </div>
      </div>
      <SectionTeam>
        <Team
          data={{
            name: "Rene Melo",
            position: "Gerente General",
            img: "https://i.postimg.cc/hjH7Nrr4/rene.jpg",
          }}
        />
        <Team
          data={{
            name: "Maria Florez",
            position: "Gerente Administrativo",
            img: "https://i.postimg.cc/pLyJ1G9w/maria.jpg",
          }}
        />
        <Team
          data={{
            name: "Daniela Ortiz",
            position: "Departamento Contable",
            img: "https://i.postimg.cc/02SbGf4x/daniela.jpg",
          }}
        />
        
        <Team
          data={{
            name: "Equipo De Produccion",
            position:"_",
            img: "https://i.postimg.cc/qMJ8jW80/produccion.jpg",
          }}
        />
        <Team
          data={{
            name: 'Valentina Carrillo',
            position:'Jefe Juridico',
            img: 'https://i.postimg.cc/Dw2bPNbs/valentina.jpg',
          }}
        />
        <Team
          data={{
            name: "Juan Diego",
            position: "Departamento De Diseño",
            img: "https://i.postimg.cc/j5bgMZxf/luis.jpg",
          }}
        />
      </SectionTeam>
      <SectionClients>
        {clientes.map((cliente) => (
          <SwiperSlide key={cliente.id}>
            <ItemCarousel
              img={cliente.img}
              company={cliente.nombre}
            />
          </SwiperSlide>
        ))}
      </SectionClients>
    </div>
  );
}
