export function SectionTeam({ children }) {
  return (
    <div>
      <h2 className="text-center mt-[90px] mb-[50px] text-[30px] font-bold">
        Nuestros Colaboradores
      </h2>
      <section className="flex flex-col md:flex-row justify-center items-center gap-[2em]  md:w-[100%] mx-auto">
        { children }
      </section>
    </div>
  );
}
