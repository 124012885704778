export function WhoUs() {
  return (
    <div className="">
      <h2 className="text-first font-black text-[22px] leading-[23px] mb-[20px]">
        ¿Quienes Somos?
      </h2>
      <p className="text-gray4 text-justify lg:text-start">
        Hydrotech Ingeniería S.A.S., sociedad perteneciente a régimen común,
        constituida de acuerdo con las Leyes colombianas de acuerdo a las
        políticas sanitarias y ambientales.
      </p>
      <div className="flex items-center my-5">
        <img
          className="h-[90px]"
          src="https://lifedesign.es/wp-content/uploads/2020/09/LIFE-DESIGN-JAIME-ARANDA-DISEN%CC%83O-DE-VIDA-DESIGN-THINKING-CAREER-COACH-ico-dt.png"
          alt="Diseñamos Soluciones"
        />
        <h2 className="text-[19px] text-first w-[100px] font-black leading-[25px] ml-[15px] ">
          Diseñamos Soluciones
        </h2>
      </div>
      <div className="flex items-center my-5">
        <img
          className="h-[90px]"
          src="https://cdn-icons-png.flaticon.com/512/1064/1064228.png"
          alt="Tecnologia Industrial"
        />
        <h2 className="text-[19px] text-first ml-[15px] font-black leading-[25px]">
          Tecnologia Industrial
        </h2>
      </div>
      <p className="text-gray4 text-justify lg:text-start">
        Nuestra empresa tiene como objeto el desarrollo integral en la
        construcción y avance de maquinaria de alta calidad versátil y adaptable
        a los requerimientos de nuestro entorno a nivel mundial; Innovando
        dentro de nuestro catálogo productos y servicios para el manejo adecuado
        de las necesidades de nuestros cliente de manera eficiente.
      </p>
    </div>
  );
}
