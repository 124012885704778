import { HashRouter, Route, Routes } from "react-router-dom";
import { NavBar } from "./componentes/navBar/NavBar";
import { Home } from "./page/Home";
import { About } from "./page/About";
import Contactanos from "./page/Contactanos";
import { NotFound } from "./page/NotFound";
import "./tailwind.css";
import Footer from "./componentes/footer/Footer";
import BtnContact from "./componentes/share/BtnContact";
import { Preloader } from "./componentes/share/Preloader";
import { Services } from "./page/Services";
import { ServiceDetails } from "./page/ServiceDetails";
import { Equipment } from "./page/Equipment";
import { EquipmentDetails } from "./page/EquipmentDetails";

export function App() {
  return (
    <HashRouter>
      <div>
        <Preloader />
        <header className="">
          <NavBar />
        </header>
        <main>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/nosotros" element={<About/>} />
            <Route path="/equipos" element={<Equipment/>} />
            <Route path="/equipo/:slog" element={<EquipmentDetails/>}/>
            <Route path="/servicios" element={<Services />} />
            <Route path="/servicio/:slog" element={<ServiceDetails/>}/>
            <Route path="/contactanos" element={<Contactanos />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <BtnContact
          address={
            "https://api.whatsapp.com/send?phone=573123876489&text=Quiero%20saber%20mas%20sobre%20sus%20servicios"
          }
        />
        <Footer />
      </div>
    </HashRouter>
  );
}
