import data from "../utils/Servicios.json";
import React from "react";
import Cabecera from "../componentes/cabeceraNav";
import { Preloader } from "../componentes/share/Preloader";
import { Card } from "../componentes/share/Card";
import { Grid } from "../componentes/share/Grid";

export function Services() {
  console.log(data);

  return (
    <div className="px-[10%] pb-[60px] dark:bg-black dark:text-white">
      <Preloader />
      <Cabecera
        paginaActual="Servicios"
        urlHome=""
        urlPage=""
        namePage="servicios"
      />
      <Grid>
        {data.map((servicio) => (
          <Card
            key={servicio.id}
            to={"/servicio/" + servicio.id}
            producto={servicio}
          />
        ))}
      </Grid>
    </div>
  );
}
