import React from "react";
import { BsWhatsapp } from "react-icons/bs";

function BtnContact({ address }) {
  return (
    <a
      className="text-[#16a34a] bg-white animate-bounce dark:bg-black dark:hover:bg-[#16a34a]  hover:bg-[#16a34a] md:p-[12px] hover:text-white duration-300 p-[8px] border-[#16a34a] fixed z-10 top-[85vh] left-[80vw] md:left-[90vw] lg:left-[90vw] border-[3px] rounded-full"
      href={address}
      target="blank"
    >
      <BsWhatsapp size={30} />
    </a>
  );
}

export default BtnContact;
