import { NavLink } from "react-router-dom";

export function Item({ children,to }) {
    return (
        <NavLink
            style={({ isActive }) => (isActive ? { color: "#0c346f", fontWeight: "bold" } : {})}
            to={to}
            className="w-fit font-medium text-md text-gray4 hover:text-blue-600 hover:text-first hover:font-semibold duration-200"
        >
            { children }
        </NavLink>
    );
}