import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    EffectFade,
    Autoplay,
  } from "swiper";
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/effect-fade";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";
  import { Swiper } from "swiper/react";
  import "./Carousel.css";

export function CarouselDetails({ children }){
    return(
        <div className="service-carousel">
          <Swiper
            modules={[
              Navigation,
              EffectFade,
              Autoplay,
              Pagination,
              Scrollbar,
              A11y,
            ]}
            spaceBetween={50}
            slidesPerView={1}
            allowTouchMove={true}
            navigation
            speed={900}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
          >
            {
                children
            }
          </Swiper>
        </div>
    )
}