export function PosterExperience() {
  return (
    <div
      className="relative">
      <div
        className="absolute left-[-2px] top-[-18px] flex flex-col justify-center items-center h-[160px] w-[160px] p-5 bg-first text-white 
                                font-bold rounded-lg shadow-solida dark:shadow-solida-black"
      >
        <p className="text-[23px]">+30</p>
        <p className="text-[22px]">Años</p>
        <p className="text-[19px]">Experiencia</p>
      </div>
      <div className="flex justify-end items-end w-full">
        <img
          className="w-[95%] lg:max-w-[600px] max-h-[400px] min-h-[312px] rounded-lg"
          src="https://i.postimg.cc/cLfr85bZ/IMG-20230512-WA0017.jpg"
          alt=""
        />
      </div>
    </div>
  );
}
