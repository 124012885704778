import React from "react";

const Contacto = () => {

    return (
        <div className="dark:bg-black dark:text-white px-[7%] md:px-[10%] py-[30px] bg-white  pb-[70px] lg:flex lg:px-[6%]">

            <div className=" rounded-xl lg:w-[50%]">
                <div className="lg:mr-14">
                    <h2 className="text-[30px]  mb-[30px] font-extrabold leading-[35px]">
                        Por favor completa el formulario para contactar con nosotros.
                    </h2>
                </div>
                <form>
                    <div className="text-gray-600 flex flex-col items-start space-y-5 md:flex-row md:flex-wrap md:space-y-0 md:gap-5 pr-[20px]">
                        <input className="p-[15px] w-full border-gray-400 border-[1px] rounded-lg md:w-[45%] " type="text" placeholder="Nombre Completo" />
                        <input className="p-[15px] w-full border-gray-400 border-[1px] rounded-lg md:w-[45%]" type="email" name="" id="" placeholder="Email" />
                        <input className="p-[15px] w-full border-gray-400 border-[1px] rounded-lg md:w-[95%]" type="text" placeholder="Asunto" />
                        <textarea className="p-[15px] w-full border-gray-400 border-[1px] rounded-lg md:w-[95%]" placeholder="Mensaje"></textarea>
                        <input className="px-[40px] py-[15px] bg-[#015FC9] rounded-xl text-white font-medium" type="button" value="Enviar" />
                    </div>
                </form>
            </div>
            <div className="mt-[30px] md:w-[95%] lg:w-[50%] lg:mt-[40px] lg:ml-10">
                <iframe className="w-full h-[450px] rounded-xl" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3978.24159371001!2d-74.38438175069486!3d4.3657516456733125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f053d5f4ac6f1%3A0x86f60631e9389678!2sHYDROTECH%20INGENIERIA%20SAS!5e0!3m2!1ses-419!2sco!4v1671238182793!5m2!1ses-419!2sco" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}

export { Contacto }