import Cabecera from "../componentes/cabeceraNav";
import { Card } from "../componentes/share/Card";
import { Grid } from "../componentes/share/Grid";
import { Preloader } from "../componentes/share/Preloader";
import equipos from "../utils/productos.json";

export function Equipment() {
  return (
    <div className="px-[10%] pb-[60px] dark:bg-black dark:text-white">
      <Preloader />
      <Cabecera paginaActual="Equipos" namePage="Equipos" />
      <Grid>
        {equipos.map((equipo) => (
          <Card key={equipo.id} to={"/equipo/" + equipo.id} producto={equipo} />
        ))}
      </Grid>
    </div>
  );
}
