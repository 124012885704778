export function InfoClients() {
  return (
    <div className="">
      <div className="py-[100px] bg-first text-white flex flex-col justify-center items-center px-[25px] md:px-[40px]">
        <h1 className="text-[30px] md:text-[35px] leading-11 font-semibold pb-12">
          Nuestros clientes nos respaldan
        </h1>
        <p className="md:text-[20px]">
          Somos una empresa colombiana que cuenta con una amplia gama de
          servicios, equipos y maquinaria para el manejo de los desechos
          sólidos, líquidos y semisolidos en los alcantarillados, pozos sépticos
          y tuberías.
        </p>
      </div>
    </div>
  );
}
