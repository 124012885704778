import React from "react";
import { PartImg } from "./PartImg";
import { Carousel } from "./Carousel";

export function SectionClients({ children }) {
  const l = [
    "https://i.postimg.cc/50ZfNbF9/cruzroja-removebg-preview.png",
    "https://i.postimg.cc/Tw7XP7SG/epc-removebg-preview.png",
    "https://i.postimg.cc/3ryvCQ23/emsersopo-removebg-preview.png",
    "https://i.postimg.cc/mkLHF4P7/cur-removebg-preview.png",
    "https://i.postimg.cc/KjBWqdFq/triple-A-removebg-preview.png",
    "https://i.postimg.cc/43KbJVMV/veolia-removebg-preview.png",
    "https://i.postimg.cc/3xrQbJqP/emserfusa-removebg-preview.png",
    "https://i.postimg.cc/8kFRMRPY/chia-removebg-preview.png",
    "https://i.postimg.cc/GmzKqc0g/deporte-removebg-preview.png",
  ];

  return (
    <div className="flex flex-col gap-[50px] lg:flex-row">
      <PartImg
        imgs={{
          firts: "https://i.postimg.cc/50ZfNbF9/cruzroja-removebg-preview.png",
          second: "https://i.postimg.cc/Tw7XP7SG/epc-removebg-preview.png",
          third: "https://i.postimg.cc/GmzKqc0g/deporte-removebg-preview.png",
        }}
      />

      <div className="w-[80%] mx-auto my-[100px] md:my-[150px] lg:w-[30%]">
        <h3 className="text-[30px] font-bold text-center mb-[40px]">
          Clientes
        </h3>
        <div className="clients">
          <Carousel>{children}</Carousel>
        </div>
      </div>
      <PartImg
        imgs={{
          firts: "https://i.postimg.cc/8kFRMRPY/chia-removebg-preview.png",
          second:
            "https://i.postimg.cc/3xrQbJqP/emserfusa-removebg-preview.png",
          third: "https://i.postimg.cc/43KbJVMV/veolia-removebg-preview.png",
        }}
      />
    </div>
  );
}
