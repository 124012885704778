import React from "react";
import { useState, useEffect } from "react";
import Logo from "../../assets/Logo.png";

function Preloader() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  return (
    <>
      {loading && (
        <div className="bg-white dark:bg-black fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center">
          <img className="w-[50px] animate-ping" src={Logo} alt="Logo Hydrotech" />
        </div>
      )}
    </>
  );
}

export { Preloader };
