import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import Logo from "../../assets/Logo.png";
import { useModeDark } from "../../hooks/useModeDark";
import { Item } from "./Item";
import { ButtonMode } from "./ButtonMode";

export function NavBar() {
  const { isDark, setTheme } = useModeDark();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <nav
        className="dark:bg-black dark:text-white w-full lg:h-[150px] pb-2 lg:pb-0 bg-white px-4 lg:px-5 shadow-md flex flex-col
                        lg:flex-row lg:justify-between lg:items-center"
      >
        <div className="flex justify-between items-center mt-3 lg:mt-0 ">
          <Link to="/">
            <img className="h-[140px]" src={Logo} alt="Hydrotech" />
          </Link>

          {/* lg no se ve */}
          <div className="lg:hidden flex gap-[10px]">
            <ButtonMode isDark={isDark} setTheme={setTheme} />
            <div className="lg:hidden">
              <button
                id="boton"
                onClick={handleClick}
                className="flex items-center p-2 rounded text-first"
              >
                <AiOutlineMenu size={45} />
              </button>
              <button>
                
              </button>
            </div>
          </div>
        </div>
        {/* lg fin no se ve */}

        <div className="dark:bg-gray-900 dark:text-gray-200 hidden bg-gray-100 lg:rounded-full font-medium lg:flex lg:gap-3 lg:p-3 lg:ml-[-110px] text-[30px]">
          <Item to={"/"}>Inicio</Item>
          <Item to={"/nosotros"}>Nosotros</Item>
          <Item to={"/servicios"}>Servicios</Item>
          <Item to={"/equipos"}>Equipos</Item>
          <Item to={"/contactanos"}>Contactanos</Item>
        </div>

        <div className="hidden lg:flex">
          <ButtonMode isDark={isDark} setTheme={setTheme} />
        </div>

        {/* este no se ve en lg menu sm y md*/}
        {isMenuOpen && (
          <div className="flex flex-col space-y-[10px] p-[15px] my-[25px] dark:bg-blueDark lg:hidden text-first px-[42%] text-center font-bold items-center">
            <Item to={"/"}>Inicio</Item>
            <Item to={"/nosotros"}>Nosotros</Item>
            <Item to={"/servicios"}>Servicios</Item>
            <Item to={"/equipos"}>Equipos</Item>
            <Item to={"/contactanos"}>Contactanos</Item>
          </div>
        )}
      </nav>
    </div>
  );
}
