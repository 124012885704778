import { useEffect, useState } from "react";


export function useModeDark(){
    const [isDark, setIsDark] = useState(false)

    useEffect(()=>{
        if (isDark) {
            document.documentElement.classList.add("dark")
        }else{
            document.documentElement.classList.remove("dark")
        }
    },[isDark])

    function setTheme(){
        setIsDark(!isDark)
    }

    return {
        isDark,
        setTheme
    }
}