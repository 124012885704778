import { Swiper } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
} from "swiper";
import "./controlesClientes.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./controlesClientes.css";

export function Carousel({ children }) {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, EffectFade, Autoplay, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      //cantidad de imagenes en la pantalla
      slidesPerView={1}
      allowTouchMove={true}
      navigation
      speed={900}
      loop={true}
      //para que las imagenes se cambien solas si se le quiere dar tiempo
      //{{delay:3000}} para que se pause al poner el mouse {pauseOnMouseEnter: true,}
      //para que siga luego de sacar el mouse { disableOnInteraction: false,}

      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
    >
      {children}
    </Swiper>
  );
}
